import { createApp } from 'vue'
import { createDynamicForms } from '@asigloo/vue-dynamic-forms';
import vueDebounce from 'vue-debounce'
import VueSmoothScroll from 'v-smooth-scroll'


import App from './App.vue'
import './assets/css/tailwind.css'
import router from './router'

const VueDynamicForms = createDynamicForms()
const Vue = createApp(App).use(router);

Vue.use(VueSmoothScroll)
Vue.use(VueDynamicForms)
Vue.use(vueDebounce)
Vue.mount("#app")
import { ref, Ref, watchEffect, computed } from "vue";
import { Cycle, Time } from "../types/choices";
import dayjs from 'dayjs'

export const boxAmount: Ref<number> = ref(2);
export const pickedCycle: Ref<Cycle> = ref("wöchentlich");
export const pickedTime: Ref<Time> = ref("12-15 Uhr");
export const pickedDate: Ref<string[]> = ref([]);
export const allDates: Ref<string[]> = ref([]);
/*todo: should be coming from server*/
const defaultPrice = 19;
export const totalPrice: Ref<string> = computed(() => (defaultPrice + boxAmount.value * 5).toFixed(2));

export const showDeliveryError: Ref<Boolean> = ref(false);

export const checkDeliveryDate = (e) => {
  if(Object.keys(pickedDate.value).length === 0) {
    e.preventDefault();
    showDeliveryError.value = true;
    document.getElementById("anchor-datepicker")?.scrollIntoView({ behavior: 'smooth' });
  }
  else {
    showDeliveryError.value = false;
    document.getElementById("login")?.scrollIntoView({ behavior: 'smooth' });
  }
}

watchEffect(()=> {
  if(Object.keys(pickedDate.value).length) {
    showDeliveryError.value = false;
  }
})

watchEffect(() => {
    let numWeeks: number;
    switch (pickedCycle.value) {
      case "wöchentlich":
        numWeeks = 1;
        break;
      case "monatlich":
        numWeeks = 4;
        break;
      case "einmalig":
        numWeeks = 0;
        break;
      default:
        numWeeks = 1
    }

    allDates.value = [];
    if(numWeeks !== 0) {
      pickedDate.value.forEach((d) => {
        const dateParts: any = d?.split(" ")?.pop()?.split(".");
        const formattedDate: any = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        allDates.value.push(dayjs(dayjs(formattedDate).toISOString()).add(numWeeks, 'week').format("ddd DD.MM.YYYY"));
        allDates.value.push(dayjs(dayjs(formattedDate).toISOString()).add(numWeeks * 2, 'week').format("ddd DD.MM.YYYY"));
        allDates.value.push(dayjs(dayjs(formattedDate).toISOString()).add(numWeeks * 3, 'week').format("ddd DD.MM.YYYY"));
      });
    }
});



<template>
  <svg
    class="drop"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background: new 0 0 512.001 512.001"
    xml:space="preserve"
  >
    <path
      style="fill: #80d3cf"
      d="M414.717,139.649l86.527-86.527l-42.366-42.364l-86.525,86.528
	c-33.986-20.926-79.08-16.683-108.533,12.769L20.106,353.768c-16.665,16.665-16.665,43.683-0.002,60.347l77.782,77.781
	c16.663,16.664,43.681,16.663,60.346-0.001L401.945,248.18C431.399,218.728,435.642,173.636,414.717,139.649z"
    />
    <rect
      x="433.464"
      y="25.055"
      transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 756.7625 410.6189)"
      style="fill: #ff6a5e"
      width="59.918"
      height="47.048"
    />
    <rect
      x="84.877"
      y="202.169"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -127.9612 215.9389)"
      style="fill: #f59d40"
      width="223.608"
      height="120.526"
    />
    <g>
      <rect
        x="193.812"
        y="222.538"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 228.1121 554.7761)"
        style="fill: #ffffff"
        width="70.283"
        height="15.213"
      />

      <rect
        x="172.289"
        y="244.065"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 176.1477 576.3046)"
        style="fill: #ffffff"
        width="70.283"
        height="15.213"
      />

      <rect
        x="150.775"
        y="265.573"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 124.2136 597.8088)"
        style="fill: #ffffff"
        width="70.283"
        height="15.213"
      />

      <rect
        x="129.269"
        y="287.087"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 72.2873 619.3286)"
        style="fill: #ffffff"
        width="70.283"
        height="15.213"
      />
    </g>
    <path
      style="fill: #311363"
      d="M512,53.122L512,53.122l-7.589-7.589L458.878,0l-0.001,0.001l0,0L414.85,44.029h0.001l-43.767,43.768
	c-13.781-7.32-29.194-11.164-44.937-11.164c-25.576,0-49.621,9.959-67.706,28.043L14.728,348.39
	c-9.497,9.496-14.727,22.123-14.727,35.553c0,13.43,5.229,26.056,14.725,35.552l77.782,77.781
	c9.495,9.495,22.12,14.725,35.55,14.726c0.001,0,0.002,0,0.003,0c13.428,0,26.054-5.23,35.55-14.727l243.716-243.715
	c15.326-15.327,25.078-35.607,27.458-57.107c2.121-19.142-1.627-38.634-10.582-55.531l43.77-43.77l0,0l29.353-29.354L512,53.122
	L512,53.122z M467.971,75.638L436.363,44.03l22.513-22.514l31.61,31.606L467.971,75.638z M408.239,143.637
	c19.631,31.884,14.832,72.662-11.672,99.165L152.853,486.517c-6.624,6.624-15.427,10.271-24.792,10.271c-0.001,0-0.002,0-0.003,0
	c-9.367-0.001-18.171-3.648-24.793-10.27l-1.237-1.237l11.001-11l-10.757-10.757l-11.001,11l-10.76-10.759l11-11.001l-10.757-10.757
	l-11.001,11.001l-10.76-10.76l11.001-11.002l-10.757-10.757l-11.002,11.002l-10.76-10.76l11.001-11.001l-10.757-10.757
	l-11.001,11.001l-1.237-1.237c-6.623-6.626-10.27-15.431-10.27-24.797s3.648-18.172,10.271-24.796l49.519-49.519l50.807,50.807
	l10.757-10.757L85.76,298.871l147.357-147.358l74.467,74.467L160.229,373.338l-12.429-12.43l-10.757,10.757l23.187,23.188
	L329.101,225.98l-85.225-85.225l10.76-10.76l65.502,65.503l10.758-10.757l-65.503-65.504l3.805-3.805
	c15.211-15.211,35.436-23.587,56.948-23.587c14.958,0,29.558,4.121,42.218,11.917l5.118,3.15l30.61-30.612l31.607,31.607
	l-30.61,30.61L408.239,143.637z M446.456,97.152l-31.607-31.607l10.757-10.758l31.608,31.607L446.456,97.152z"
    />
    <g>
      <rect
        x="341.814"
        y="234.116"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 414.3051 686.9314)"
        style="fill: #ffffff"
        width="15.213"
        height="47.089"
      />
      <path
        style="fill: #ffffff"
        d="M385.811,232.045l-10.758-10.757c16.496-16.495,19.488-41.867,7.278-61.7l12.954-7.976
		C411.206,177.471,407.309,210.547,385.811,232.045z"
      />
    </g>
  </svg>
</template>
